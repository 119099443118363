<template>
  <main class="equal-time-meeting-view">
    <div class="equal-time-top">
      <h6 class="equal-time-title">
        <a
          class="plain"
          @click="
            openUrl(
              'https://app.equaltime.io/?utm_source=zoom_app&utm_medium=zoom&utm_campaign=zoom&utm_content=header_logo'
            )
          "
        >
          <equalicert-logo />
          Equal Time {{ devMode ? "DEV" : "" }}
        </a>
      </h6>
    </div>
    <div class="spacer--half"></div>
    <div class="equal-time-body">
      <div
        v-if="!isLoading && breakoutRoomState === 'open'"
        class="equal-time-section"
      >
        Equal Time is paused when in breakout room. Close breakout rooms to
        re-enable.
      </div>
      <div v-else-if="!ownerAppNotActive">
        <!-- hide this div if the user has already connected their calendar -->
        <div v-if="!userInfo?.recallAiAuthToken">
          <button
            @click="
              openUrl(
                'https://app.equaltime.io/zoom?utm_source=zoom_app&utm_medium=zoom&utm_campaign=zoom&utm_content=complete_setup_cta'
              )
            "
            class="button"
          >
            Complete Account Setup
          </button>
          <div class="spacer--half"></div>
          <p>Please complete your account setup by connecting your calendar.</p>
          <div class="spacer"></div>
        </div>
        <speaking-time-skeleton v-if="isLoading" />
        <div v-else class="equal-time-section">
          <h6 class="space-bottom">
            Speaking Time
            <sort-icon
              class="clickable"
              @click="sortBy === 'low' ? (sortBy = 'high') : (sortBy = 'low')"
            />
            <time-icon
              v-if="showPercentages"
              class="clickable"
              @click="showPercentages = false"
            />
            <percent-icon
              v-else
              class="clickable"
              @click="showPercentages = true"
            />
          </h6>
          <table class="equal-time-table talking">
            <tr
              v-for="participant in sortedParticipants"
              v-bind:key="participant.id"
            >
              <td>
                <span class="equal-time-name u-flex">
                  <template v-if="participant.excluded">
                    <del class="space-right">{{ participant.screenName }}</del>
                    <span
                      class="equal-time-pct zero"
                      :onClick="() => setParticipantExcluded(participant.uuid)"
                    >
                      include
                    </span>
                  </template>
                  <template v-else>
                    {{ participant.screenName }}
                  </template>
                </span>
                <div class="equal-time-buttons" v-if="!participant.excluded">
                  <button
                    :class="`equal-time-button A ${
                      participant.group === groupALabel ? 'pressed' : ''
                    }`"
                    :onClick="
                      () => setParticipantGroup(participant.uuid, groupALabel)
                    "
                  >
                    {{ groupALabel }}
                  </button>
                  <button
                    :class="`equal-time-button B ${
                      participant.group === groupBLabel ? 'pressed' : ''
                    }`"
                    :onClick="
                      () => setParticipantGroup(participant.uuid, groupBLabel)
                    "
                  >
                    {{ groupBLabel }}
                  </button>
                  <button
                    :class="`equal-time-button C ${
                      participant.group === groupCLabel ? 'pressed' : ''
                    }`"
                    :onClick="
                      () => setParticipantGroup(participant.uuid, groupCLabel)
                    "
                  >
                    {{ groupCLabel }}
                  </button>
                </div>
                <div
                  v-if="
                    !participant.excluded &&
                    participant.total / totalDuration > 0
                  "
                  :class="`equal-time-pct ${getDurationColor(
                    participant.total
                  )}`"
                  :style="{
                    width: `${(participant.total / totalDuration) * 100}%`,
                  }"
                >
                  <span v-if="showPercentages">
                    {{ Math.round((participant.total / totalDuration) * 100) }}%
                  </span>
                  <span v-else>
                    {{ formatDuration(participant.total) }}
                  </span>
                </div>
                <div
                  v-else-if="!participant.excluded"
                  class="equal-time-pct zero"
                  :onClick="() => setParticipantExcluded(participant.uuid)"
                >
                  Hasn't spoken yet. Click here to exclude from metrics.
                </div>
              </td>
            </tr>
          </table>
          <div class="random-participant-picker">
            <p>Random Participant Picker:</p>
            <p
              v-if="randomParticipant"
              @click="pickRandomParticipant"
              class="card small clickable"
            >
              {{ randomParticipant }}
              <shuffle-icon class="clickable" />
            </p>
            <p
              v-else
              class="card small clickable"
              @click="pickRandomParticipant"
            >
              Choose Equitably
              <shuffle-icon class="clickable" />
            </p>
          </div>
        </div>

        <div class="equal-time-section">
          <monologues-skeleton v-if="isLoading" />
          <div v-else class="l-grid l-grid--2up l-grid--2up--small">
            <div
              @click="inclusionScoreOpen = !inclusionScoreOpen"
              class="card clickable u-align-items--center"
            >
              <div class="spacer--quarter"></div>
              <h6>Inclusion Score</h6>
              <div class="spacer--quarter"></div>
              <donut-chart :to="inclusionScore" />
            </div>
            <div>
              <div
                @click="monologuesOpen = !monologuesOpen"
                class="card clickable u-align-items--center"
              >
                <h1>{{ monologueCount }}</h1>
                <h6>Monologues</h6>
              </div>
              <div class="spacer--half"></div>
              <div
                @click="restartOpen = !restartOpen"
                class="card purple clickable u-align-items--center"
              >
                <restart-icon />
                <h6>Restart Meeting</h6>
              </div>
            </div>
          </div>
        </div>

        <div v-if="inclusionScoreOpen" class="equal-time-section">
          <div class="u-flex u-align-items--center space-bottom">
            <h6 class="space-right">Inclusion Score</h6>
            <x-icon @click="inclusionScoreOpen = false" class="clickable" />
          </div>
          <p class="space-bottom">
            Your inclusion score is on a scale of 0 to 100, and is calculated
            based on the participation and monologue scores:
          </p>
          <p>
            <strong>Participation Score: {{ participationScore }}/80</strong>
          </p>
          <p class="space-bottom">
            Your Participation Score reflects how well speaking times are
            balanced. If everyone is in the green, you get a perfect score. If
            everyone is in the red, you get no points.
          </p>
          <p>
            <strong>Monologue Score: {{ monologueScore }}/20</strong>
          </p>
          <p class="space-bottom">
            Your Monologue Score is calculated based on how many monologues
            you've had so far. If you have no monologues, you get a perfect
            score.
          </p>
        </div>

        <div v-if="monologuesOpen" class="equal-time-section">
          <div class="u-flex u-align-items--center space-bottom">
            <h6 class="space-right">Monologues</h6>
            <x-icon @click="monologuesOpen = false" class="clickable" />
          </div>
          <table id="equal-time-monologues-table" class="equal-time-table">
            <tbody>
              <tr
                v-for="participant in participants"
                v-bind:key="participant.participantUUID"
              >
                <td>
                  <p>
                    {{ participant.screenName }}: {{ participant.monologues }}
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div v-if="restartOpen" class="equal-time-section">
          <div class="u-flex u-align-items--center space-bottom">
            <h6 class="space-right">Restart Equal Time</h6>
            <x-icon @click="restartOpen = false" class="clickable" />
          </div>
          <p class="space-bottom--half">
            Doing this will zero out your meeting metrics and start fresh.
          </p>
          <button
            v-if="VITE_FEATURE_MEETING_RESET"
            class="button"
            @click="resetMeeting()"
          >
            Restart Now
          </button>
        </div>

        <div class="spacer--half"></div>

        <pie-chart-skeleton v-if="isLoading" />
        <div v-else class="equal-time-section">
          <h6 class="space-bottom u-align-items--center">
            Group Speaking Balance
          </h6>
          <pie-chart
            :group-a-label="groupALabel"
            :group-b-label="groupBLabel"
            :group-c-label="groupCLabel"
          />
        </div>

        <Transition v-if="settingsToastOpen">
          <div class="toast settings">
            <div class="toast-dismiss" @click="settingsToastOpen = false">
              <dash-icon class="white" />
            </div>
            <h6>Meeting Settings</h6>
            <div class="spacer--half"></div>
            <div class="u-fit-width u-margin-auto">
              <div class="u-flex u-align-items--center">
                <p class="space-right">Group A Label:</p>
                <input type="text" v-model="groupALabel" />
              </div>
              <div class="spacer--half"></div>
              <div class="u-flex u-align-items--center">
                <p class="space-right">Group B Label:</p>
                <input type="text" v-model="groupBLabel" />
              </div>
              <div class="spacer--half"></div>
              <div class="u-flex u-align-items--center">
                <p class="space-right">Group C Label:</p>
                <input type="text" v-model="groupCLabel" />
              </div>
            </div>
            <div class="spacer--half"></div>
            <div class="spacer--quarter"></div>
            <button @click="settingsToastOpen = false" class="button ghost">
              Close Settings
            </button>
            <div class="spacer--half"></div>
          </div>
        </Transition>

        <Transition v-if="roundsToastOpen">
          <div class="toast">
            <div class="toast-dismiss" @click="roundsToastOpen = false">
              <dash-icon class="white" />
            </div>
            <h4>Equal Rounds</h4>
            <div class="spacer--quarter"></div>
            <p>
              Allocate equal time for everyone. If a participant goes over the
              time limit, their speaking bar will turn red.
            </p>
            <div class="spacer--half"></div>
            <div class="u-flex u-align-items--center u-fit-width u-margin-auto">
              <input
                id="minutes"
                type="number"
                v-model="roundsTimeLimit"
                min="0"
                class="space-right"
                style="width: 60px"
              />
              <label for="minutes" class="space-right">minutes</label>
            </div>
            <div class="spacer--half"></div>
            <div class="spacer--quarter"></div>
            <button
              v-if="!roundsActive"
              @click="startRounds()"
              class="button green"
              style="width: 80px"
            >
              Start
            </button>
            <button
              v-else
              @click="stopRounds()"
              class="button red"
              style="width: 80px"
            >
              Stop
            </button>
            <button @click="shareApp()" class="button ghost space-left">
              Share Screen
            </button>
          </div>
        </Transition>

        <Transition>
          <div class="toast border" v-if="feelingToastOpen">
            <div class="toast-dismiss" @click="this.feelingToastOpen = false">
              <dash-icon class="white" />
            </div>
            <h6>
              {{
                feelingSubmitted
                  ? "Thank You!"
                  : `I feel able to speak my mind in this meeting.`
              }}
            </h6>
            <div class="toast__buttons">
              <button
                @click="clickRating(1)"
                class="circle-icon circle-icon--red"
              >
                ➊
              </button>
              <button
                @click="clickRating(2)"
                class="circle-icon circle-icon--red"
              >
                ➋
              </button>
              <button
                @click="clickRating(3)"
                class="circle-icon circle-icon--yellow"
              >
                ➌
              </button>
              <button
                @click="clickRating(4)"
                class="circle-icon circle-icon--yellow"
              >
                ➍
              </button>

              <button
                @click="clickRating(5)"
                class="circle-icon circle-icon--yellow"
              >
                ➎
              </button>

              <button
                @click="clickRating(6)"
                class="circle-icon circle-icon--green"
              >
                ➏
              </button>

              <button
                @click="clickRating(7)"
                class="circle-icon circle-icon--green"
              >
                ➐
              </button>
            </div>
            <div class="agree-disagree">
              <div>disagree</div>
              <div>agree</div>
            </div>
          </div>
        </Transition>
      </div>

      <div v-else-if="ownerAppNotActive">
        <p>
          It looks like you are not the host of this meeting 😢 Non-host support
          is available by inviting the Equal Time bot 🤖 to your call.
        </p>
        <div class="spacer--half"></div>
        <p>
          <strong>Let's get you set up!</strong> Book your personalized 15 min
          set-up call now with a member of our team (Free!)
        </p>
        <div class="spacer--half"></div>
        <button
          @click="openUrl('https://calendly.com/rachel-dowling/15min')"
          class="button"
        >
          Book Your FREE Set-up call now
        </button>
      </div>

      <Transition v-if="meeting && tagToastOpen">
        <div class="toast">
          <div class="toast-dismiss">
            <button @click="tagToastOpen = fasle">
              <dash-icon class="white" />
            </button>
          </div>
          <h6>What type of<br />meeting is this?</h6>
          <select v-model="meeting.type" @change="onMeetingTypeChange">
            <option value="team meeting">Team Meeting</option>
            <option value="1:1 meeting">1:1 Meeting</option>
            <option value="presentation">Presentation</option>
            <option value="class">Class</option>
            <option value="other">Other</option>
          </select>
          <div class="spacer--half"></div>
          <p>
            Add a meeting tag to customize your Equal Time experience and view
            trends over time.
          </p>
        </div>
      </Transition>

      <Transition v-show="meeting && timerToastOpen">
        <div class="toast">
          <div class="toast-dismiss" @click="timerToastOpen = false">
            <dash-icon class="white" />
          </div>
          <countdown-timer />
        </div>
      </Transition>

      <Transition v-show="meeting && iceBreakerToastOpen">
        <div class="toast">
          <div class="toast-dismiss" @click="iceBreakerToastOpen = false">
            <dash-icon class="white" />
          </div>
          <ice-breakers />
        </div>
      </Transition>

      <Transition v-show="meeting && transcriptsToastOpen">
        <div class="toast">
          <div class="toast-dismiss" @click="transcriptsToastOpen = false">
            <dash-icon class="white" />
          </div>
          <transcripts-toast />
        </div>
      </Transition>

      <div class="equal-time-toolbar">
        <div>
          <timer-icon @click="timerToastOpen = true" />
        </div>
        <div v-if="!ownerAppNotActive">
          <tag-icon @click="tagToastOpen = true" />
        </div>
        <div v-if="!ownerAppNotActive">
          <users-icon @click="roundsToastOpen = true" />
        </div>
        <div>
          <ice-breaker-icon @click="iceBreakerToastOpen = true" />
        </div>
        <div>
          <transcript-icon @click="transcriptsToastOpen = true" />
        </div>
        <div v-if="!ownerAppNotActive">
          <settings-icon @click="settingsToastOpen = !settingsToastOpen" />
        </div>
        <div>
          <help-icon
            @click="
              openUrl(
                'https://app.equaltime.io/help?utm_source=zoom_app&utm_medium=zoom&utm_campaign=zoom&utm_content=toolbar_help'
              )
            "
          />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import config from "../plugins/config";
import useStore from "../stores";
import { mapState } from "pinia";
import EqualicertLogo from "../components/EqualicertLogo.vue";
import SpeakingTimeSkeleton from "../components/SpeakingTimeSkeleton.vue";
import MonologuesSkeleton from "../components/MonologuesSkeleton.vue";
import PieChartSkeleton from "../components/PieChartSkeleton.vue";
import TimeIcon from "../components/TimeIcon.vue";
import TimerIcon from "../components/TimerIcon.vue";
import HelpIcon from "../components/HelpIcon.vue";
import RestartIcon from "../components/RestartIcon.vue";
import TranscriptIcon from "../components/TranscriptIcon.vue";
import DonutChart from "../components/DonutChart.vue";
import PieChart from "../components/PieChart.vue";
import PercentIcon from "../components/PercentIcon.vue";
import SortIcon from "../components/SortIcon.vue";
import ShuffleIcon from "../components/ShuffleIcon.vue";
import DashIcon from "../components/DashIcon.vue";
import XIcon from "../components/XIcon.vue";
import CountdownTimer from "../components/CountdownTimer.vue";
import UsersIcon from "../components/UsersIcon.vue";
import TagIcon from "../components/TagIcon.vue";
import SettingsIcon from "../components/SettingsIcon.vue";
import IceBreakers from "../components/IceBreakers.vue";
import IceBreakerIcon from "../components/IceBreakerIcon.vue";
import TranscriptsToast from "../components/TranscriptsToast.vue";

console.log("[MeetingView init]");

const MEETING_SURVEY_TIMEOUT_MS = 1000 * 60 * 10; // 10 minutes
export default {
  name: "MeetingView",
  watch: {
    isLoading() {
      console.log("[isLoading]", this.isLoading);
    },
  },
  mounted: async function () {
    if (config.VITE_FEATURE_MEETING_SURVEY) {
      setTimeout(() => {
        this.feelingToastOpen = true;
      }, MEETING_SURVEY_TIMEOUT_MS);
    }
  },
  data: () => {
    return {
      feelingSubmitted: false,
      feelingToastOpen: false,
      groupALabel: "A",
      groupBLabel: "B",
      groupCLabel: "C",
      iceBreakerToastOpen: false,
      meetingId: null,
      meetingTypeToastOpen: false,
      inclusionScoreOpen: false,
      monologuesOpen: false,
      randomParticipant: null,
      restartOpen: false,
      roundsActive: false,
      roundsTimeLimit: 5,
      roundsToastOpen: false,
      showPercentages: true,
      settingsToastOpen: false,
      sortBy: "high",
      timerToastOpen: false,
      tagToastOpen: false,
      transcriptsToastOpen: false,
    };
  },
  components: {
    TagIcon,
    TranscriptIcon,
    DonutChart,
    EqualicertLogo,
    HelpIcon,
    TimeIcon,
    TimerIcon,
    MonologuesSkeleton,
    SpeakingTimeSkeleton,
    PieChartSkeleton,
    RestartIcon,
    XIcon,
    PieChart,
    ShuffleIcon,
    PercentIcon,
    DashIcon,
    SortIcon,
    CountdownTimer,
    UsersIcon,
    SettingsIcon,
    IceBreakers,
    IceBreakerIcon,
    TranscriptsToast,
  },
  methods: {
    onMeetingTypeChange: async function (event) {
      await this.store.setMeetingType(
        event.target.value ? event.target.value : "other"
      );
      this.tagToastOpen = false;
    },
    resetMeeting: async function () {
      console.log("[resetMeeting]");
      await this.store.resetMeeting();
    },
    setParticipantGroup: async function (uuid, group) {
      if (this.participants[uuid].group === group) {
        await this.store.setParticipantGroup(uuid, "NA");
      } else {
        await this.store.setParticipantGroup(uuid, group);
      }
    },
    setParticipantExcluded: async function (uuid) {
      if (this.participants[uuid].excluded) {
        await this.store.setParticipantExcluded(uuid, false);
      } else {
        await this.store.setParticipantExcluded(uuid, true);
      }
    },
    shareApp: async function () {
      await window.zoomSdk.callZoomApi("shareApp", { action: "start" });
    },
    openUrl: async function (url) {
      window.zoomSdk.openUrl({ url });
    },
    clickRating: async function (rating) {
      await this.store.saveMeetingRating(rating);

      this.feelingSubmitted = true;
      await this.$forceUpdate();
      this.feelingToastOpen = false;
    },
    // format the time (in seconds) to days, hours and minutes
    formatDuration(duration) {
      let hours = Math.floor(duration / 3600) % 24;
      if (hours < 10) {
        hours = `0${hours}`;
      }
      let minutes = Math.floor(duration / 60) % 60;
      if (minutes < 10) {
        minutes = `0${minutes}`;
      }
      let seconds = Math.floor(duration) % 60;
      if (seconds < 10) {
        seconds = `0${seconds}`;
      }
      if (hours === "00") {
        return `${minutes}:${seconds}`;
      }
      return `${hours}:${minutes}:${seconds}`;
    },
    getDurationColor(total, calculateInclusionScore) {
      if (this.roundsActive && !calculateInclusionScore) {
        if (total < this.roundsTimeLimit * 60) {
          return "green";
        }
        return "red";
      } else {
        const percentage = (total / this.totalDuration) * 100;
        let p = this.attendeeCount;
        // return green if there's only 1 participant
        if (p === 1) {
          return "green";
        }
        // formula doesn't work for 2 participants, so we manually set it
        if (p === 2) {
          if (percentage > 25 && percentage < 75) {
            return "green";
          }
          if (percentage < 12.5) {
            return "red";
          }
          if (percentage > 87.5) {
            return "red";
          }
          return "yellow";
        }
        let greenLow = 100 / p - 100 / p / (p / 2);
        let greenHigh = 100 / p + 100 / p / (p / 2);
        let redLow = greenLow / 2;
        let redHigh = greenHigh / 2 + greenHigh;
        if (percentage > greenLow && percentage < greenHigh) {
          return "green";
        }
        if (percentage < redLow) {
          return "red";
        }
        if (percentage > redHigh) {
          return "red";
        }
        return "yellow";
      }
    },
    pickRandomParticipant: function () {
      // convert participants object of objects into array of objects
      const participantsArray = Object.values(this.participants);
      // remove participants who have been excluded
      const filteredParticipantsArray = participantsArray.filter(
        (participant) => !participant.excluded
      );
      let randomIndex = Math.floor(
        Math.random() * filteredParticipantsArray.length
      );
      this.randomParticipant =
        filteredParticipantsArray[randomIndex].screenName;
    },
    startRounds: function () {
      this.roundsActive = true;
      this.showPercentages = false;
    },
    stopRounds: function () {
      this.roundsActive = false;
      this.showPercentages = true;
    },
  },
  setup() {
    const store = useStore();
    return {
      store,
      devMode: import.meta.env.DEV,
      VITE_FEATURE_MEETING_RESET: config.VITE_FEATURE_MEETING_RESET,
    };
  },
  computed: {
    ...mapState(useStore, [
      "participants",
      "activeSpeakerStartTimestamp",
      "meetingUUID",
      "isBreakoutRoom",
      "ownerAppNotActive",
      "breakoutRoomState",
      "isLoading",
      "userInfo",
      "meeting",
    ]),
    // computed property for the participants array
    sortedParticipants: function () {
      // convert participants object of objects into array of objects
      const participantsArray = Object.values(this.participants);
      //sort participantsArray by total
      if (this.sortBy === "high") {
        participantsArray.sort((a, b) => {
          return b.total - a.total;
        });
      } else if (this.sortBy === "low") {
        participantsArray.sort((a, b) => {
          return a.total - b.total;
        });
      }
      return participantsArray;
    },
    attendeeCount: function () {
      let count = 0;
      for (let speakerId in this.participants) {
        if (!this.participants[speakerId].excluded) {
          count++;
        }
      }
      return count;
    },
    monologueCount: function () {
      let count = 0;
      for (let speakerId in this.participants) {
        if (!this.participants[speakerId].excluded) {
          count += this.participants[speakerId].monologues;
        }
      }
      return count;
    },
    monologueScore: function () {
      if (this.monologueCount > 5) {
        return 0;
      }
      if (this.monologueCount > 2) {
        return 4;
      }
      if (this.monologueCount > 0) {
        return 10;
      }
      return 20;
    },
    participationScore: function () {
      // count the current number of participants in the red and in the yellow
      const attendeeCount = this.attendeeCount;
      let yellow = 0;
      let red = 0;
      for (let speakerId in this.participants) {
        if (
          !this.participants[speakerId].excluded &&
          this.getDurationColor(this.participants[speakerId].total, true) ===
            "yellow"
        ) {
          yellow++;
        }
        if (
          !this.participants[speakerId].excluded &&
          this.getDurationColor(this.participants[speakerId].total, true) ===
            "red"
        ) {
          red++;
        }
      }
      // return 80 if there's 0 or 1 non-excluded participant or everyone is in the green
      if (attendeeCount <= 1 || (red === 0 && yellow === 0)) {
        return 80;
      }
      // return 0 if everyone is in the red
      if (red === attendeeCount) {
        return 0;
      }
      // return 40 if everyone is in the yellow
      if (red === 0 && yellow === attendeeCount) {
        return 40;
      }
      // return 10 if all but 1 are in the red
      if (red === attendeeCount - 1) {
        return 10;
      }
      // return 20 if half or less are in the red
      if (red >= attendeeCount / 2 && red > 0) {
        return 20;
      }
      // return 30 if a quarter or less are in the red
      if (red >= attendeeCount / 4 && red > 0) {
        return 30;
      }
      // return 50 if all but 1 are in the yellow
      if (red === 0 && yellow === attendeeCount - 1) {
        return 50;
      }
      // return 60 if there are no reds and half or less are in the yellow
      if (red === 0 && yellow >= attendeeCount / 2) {
        return 60;
      }
      // return 70 if there are no reds and a quarter or less are in the yellow
      if (red === 0 && yellow >= attendeeCount / 4) {
        return 70;
      }
      // return 80 as the default
      return 80;
    },
    inclusionScore: function () {
      const participation = this.participationScore || 0;
      const monologue = this.monologueScore || 0;
      const score = participation + monologue;
      return score;
    },
    totalDuration: function () {
      let totalDuration = 0;
      if (!this.participants) {
        return null;
      }
      for (let speakerId in this.participants) {
        totalDuration += this.participants[speakerId].total;
      }
      return totalDuration;
    },
  },
};
</script>

<style scoped lang="scss">
.equal-time-body {
  background-color: var(--color-white);
  padding: 10px 10px 100px;
  font-size: 14px;
  line-height: 20px;
}

.equal-time-buttons {
  display: flex;
}

/* main button */
.equal-time-button,
.equal-time-button:visited,
.equal-time-button:focus,
.equal-time-button:active {
  border-radius: 8px;
  display: inline-block;
  cursor: pointer;
  display: inline-block;
  background-color: var(--color-white);
  color: var(--color-primary);
  border: solid 1px var(--color-gray);
  text-transform: uppercase;
  padding: 0 0.5em;
  height: 20px;
  font-size: 12px;
  line-height: 12px;
  margin-left: 4px;
  transition: var(--transition);
  opacity: 1;
}
.equal-time-button:hover {
  opacity: var(--opacity-on-hover);
}

.equal-time-button.pressed {
  background-color: var(--color-light-gray);
  &:hover {
    opacity: 1;
  }
}

/* talk time bar chart */
.equal-time-name {
  float: left;
  margin: 0 4px 0 0;
  line-height: 32px;
}

.equal-time-pct {
  clear: both;
  height: 22px;
  color: var(--color-black);
  background: var(--color-light-gray);
  line-height: 15px;
  margin-bottom: 12px;
  padding: 4px;
  transition: linear all 250ms;
  font-size: 12px;
  border-radius: 8px;
  padding-left: 8px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}
.talking .equal-time-pct.zero {
  display: flex;
  background: none;
  border: solid 1px var(--color-light-gray);
  width: fit-content;
  padding-right: 8px;
  cursor: pointer;
  opacity: 1;
  transition: var(--transition);
  &:hover {
    opacity: var(--opacity-on-hover);
  }
}

.talking .equal-time-pct.NA {
  background: var(--color-light-gray);
}

.talking .equal-time-pct.A {
  /* background: var(--color-secondary); */
  background: var(--color-black);
}

.talking .equal-time-pct.B {
  /* background: var(--color-tertiary-lighter); */
  background: var(--color-black);
}

.talking .equal-time-pct.C {
  /* background: var(--color-quaternary); */
  background: var(--color-black);
}

.talking .equal-time-pct.green {
  background: var(--color-medium-green);
}
.talking .equal-time-pct.yellow {
  background: var(--color-yellow);
}
.talking .equal-time-pct.red {
  background: var(--color-medium-red);
}

/* headers */
.equal-time-top {
  background-color: var(--color-primary);
  padding: 10px;
  position: relative;
  border-bottom-left-radius: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--color-white);
  a {
    color: var(--color-white);
  }
}

.equal-time-top .equal-time-title {
  width: fit-content;
  color: var(--color-white);
  margin: 0;
  line-height: 25px;
}

.equal-time-header {
  color: var(--color-primary);
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  font-family: "DM Sans", Helvetica, Arial, sans-serif;
  font-family: var(--font-family-secondary);
  letter-spacing: var(--letter-spacing-secondary);
  font-weight: var(--font-weight-secondary);
  line-height: 1.8;
  line-height: var(--line-height-6);
  font-size: 18px;
  font-size: var(--font-size-6);
  font-weight: 300;
}
.equal-time-section {
  padding-bottom: 28px;
}

.equal-time-name,
#equal-time-monologues-table td {
  font-family: "Hind", Helvetica, Arial, sans-serif;
  font-family: var(--font-family-primary);
  letter-spacing: var(--letter-spacing-primary);
  font-weight: var(--font-weight-primary);
  line-height: 1.8;
  line-height: var(--line-height-5);
  font-size: 16px;
  font-size: var(--font-size-4);
  font-weight: 300;
  letter-spacing: 0.5px;
}

.settings input {
  width: 100px;
}

.random-participant-picker {
  margin: 4px 0 1rem 3px;
  p {
    line-height: 18px;
    font-size: 15px;
  }
  .card {
    font-size: 12px;
    margin-top: 6px;
    width: fit-content;
  }
}

// toolbar

.equal-time-toolbar {
  z-index: 1;
  background: var(--color-black);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  bottom: 10px;
  right: 10px;
  position: fixed;
  left: 10px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.equal-time-toolbar div {
  margin: 0 0.3rem;
  cursor: pointer;
  opacity: 1;
  transition: var(--transition);
}

.equal-time-toolbar div:hover {
  opacity: var(--opacity-on-hover);
}

.equal-time-toolbar p {
  color: var(--color-white);
  font-size: 0.5rem;
  line-height: 0.6rem;
}

// icons

.equalicert-icon {
  margin-right: 10px;
  float: left;
  width: 24px;
  height: 24px;
}

.equal-time-toolbar svg,
.x-icon,
.dash-icon,
.users-icon {
  width: 24px;
  height: 24px;
  margin-top: 2px;
}

.timer-icon,
.ice-breaker-icon {
  width: 23px;
  height: 23px;
}

.restart-icon {
  width: 32px;
  height: 32px;
  margin-bottom: 4px;
}

.speaking-time-icon,
.shuffle-icon {
  height: 18px;
  margin-left: 4px;
  width: auto;
}

.time-icon,
.percent-icon,
.sort-icon {
  height: 18px;
  margin: 0 0 -1.5px 6px;
  width: auto;
}

.shuffle-icon {
  height: 13px;
  margin-bottom: -2px;
}

.settings-icon {
  width: 22px;
  height: 22px;
  margin-top: -1px;
}

.circle-icon {
  font-size: 35px;
}

.circle-icon--red {
  color: var(--color-medium-red);
}

.circle-icon--yellow {
  color: var(--color-yellow);
}

.circle-icon--green {
  color: var(--color-green);
}
</style>
